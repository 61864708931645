.sidebar {
  display: flex;
  flex-direction: column;
  flex: 0.35;
}
.sidebar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 10px;
  border-right: 1px solid lightgray;
}
.sidebar__headerRight {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 10vw;
}
.sidebar__headerRight > .MuiSvgIcon-root {
  margin-right: 2vw;
  font-size: 24px !important;
}
.sidebar__search {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  height: 39px;
  padding: 10px;
}
.sidebar__searchContainer {
  display: flex;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 35px;
  border-radius: 20px;
}
.sidebar__searchContainer > .MuiSvgIcon-root {
  color: gray;
  padding: 10px;
}
.sidebar__searchContainer > input {
  border: none;
  outline-width: 0;
  margin-left: 10px;
}
.sidebar__chats {
  background-color: white;
  flex: 1;
  overflow-y: scroll;
}
/* Hide scrollbar for Chrome, Safari, and Opera */
.sidebar__chats::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge, and FireFox */
.sidebar__chats {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.menu-primary-enter {
  opacity: 0;
}
.menu-primary-enter-active {
  opacity: 1;
  transition: all var(--speed) ease;
}

.menu-primary-enter-done {
  transform: translateY(3px) !important;
}
