.chat {
  display: flex;
  flex-direction: column;
  flex: 0.65;
}
.chat__header {
  padding: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
}
.chat__headerInfo {
  flex: 1; /** push content as far as possible */
  padding-left: 20px;
}
.chat__headerInfo {
  margin-bottom: 3px;
  font-weight: 500;
}
.chat__headerInfo p {
  color: #969496;
  font-size: 14px;
}
.chat__body {
  flex: 1; /** take up all of vertical width */
  background-image: url("https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png");
  background-repeat: repeat;
  background-position: center;
  padding: 30px;
  padding-bottom: 0;
  overflow-y: scroll;
}
.chat__body::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge, and FireFox */
.sidebar__chats {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.chat__message {
  position: relative;
  font-size: 16px;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  margin-bottom: 40px;
  width: fit-content;
}
.chat__timestamp {
  margin-left: 10px;
  font-size: xx-small;
}
.chat__name {
  position: absolute;
  top: -15px;
  left: 10px;
  font-weight: 700;
  font-size: xx-small;
}
.chat__reciever {
  margin-left: auto;
  background-color: #dcf8c6;
  max-width: 45%;
  overflow-wrap: break-word;
}
.chat__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  border-top: 1px solid lightgray;
}
.chat__footer > form {
  flex: 1;
  display: flex;
}
.inputstyling {
  flex: 1;
  border-radius: 30px;
  padding: 10px;
  outline-width: 0;
  border: none;
}
.chat__footer > form > button,
.sidebar__search form > button {
  display: none; /** button functionality still there */
}
.chat__footer .MuiSvgIcon-root {
  padding: 10px;
  color: gray;
}
